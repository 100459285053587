<template>
  <v-container>
    <v-row v-if="!showFlowComponent.flag">
      <v-col>
        <v-row>
          <v-col cols="12" xl="5" lg="5" md="5">
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-magnify"
              label="Buscar..."
              clearable
              v-model="search"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" xl="1" lg="2" md="3">
            <v-menu
              offset-y
              :close-on-click="true"
              :close-on-content-click="false"
              v-model="filterMenu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on" block>
                  <v-icon left>mdi-filter</v-icon>
                  Filtro
                </v-btn>
              </template>
              <v-card width="auto">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          label="Tipo"
                          hide-details
                          outlined
                          clearable
                          dense
                          :items="moTypes"
                          hide-no-data
                          item-text="name"
                          v-model="filter.type"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          label="Simbiosis"
                          hide-details
                          outlined
                          clearable
                          dense
                          :items="symbiosis"
                          item-text="name"
                          hide-no-data
                          v-model="filter.symbiosis"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          label="Estatus"
                          hide-details
                          outlined
                          clearable
                          dense
                          :items="statusMarker"
                          item-text="name"
                          hide-no-data
                          v-model="filter.isActive"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="warning" @click="cleanFilter()"
                    >Restaurar</v-btn
                  >
                  <v-btn color="primary" @click="applyFilter()">Aplicar</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="12" xl="1" lg="2" md="3">
            <v-btn color="primary" @click="openFormNewMarrker()">
              <v-icon>mdi-plus</v-icon>
              Añadir marcador
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card elevation="3">
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="headers"
                :items="markers"
                :page.sync="page"
                :items-per-page="10"
                :loading="loadTable"
                hide-default-footer
                fixed-header
                height="56vh"
                no-data-text="No hay niveles de riesgo"
                class="elevation-0"
              >
                <template #[`item.molecularMarkerID`]="{ item }">
                  <div v-if="item.markerType == 'MO'">
                    <span class="red--text" v-if="item.isActive == false">
                      {{ item.molecularMarkerID }}
                    </span>
                    <span v-else>
                      {{ item.molecularMarkerID }}
                    </span>
                  </div>
                  <div v-else>--------</div>
                </template>
                <template #[`item.microorganism_meta.name`]="{ item }">
                  <div v-if="item.markerType == 'MO'">
                    <span class="red--text" v-if="item.isActive == false">
                      {{ item.microorganism_meta.name }}
                    </span>
                    <span v-else>
                      {{ item.microorganism_meta.name }}
                    </span>
                  </div>
                  <div v-else>
                    {{ item.soilFunction.es }}
                  </div>
                </template>
                <template #[`item.updatedAt`]="{ item }">
                  <span>{{ dateFormat(item.updatedAt) }} </span>
                </template>
                <template #[`item.createdAt`]="{ item }">
                  <span>{{ dateFormat(item.createdAt) }} </span>
                </template>
                <template #[`item.microorganism_meta.moType`]="{ item }">
                  <div v-if="item.markerType == 'MO'">
                    <span
                      >{{ getMOType(item.microorganism_meta.moType) }}
                    </span>
                    <span v-if="item.isPathogen"> (Patógeno) </span>
                    <span v-else> (Benéfico) </span>
                  </div>
                  <div v-else>Funciónal</div>
                </template>

                <template #[`item._id`]="{ item }">
                  <template v-if="item.isActive == false">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="warning"
                          dark
                          x-small
                          class="rounded-md mx-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="setRestoreModal(item)"
                        >
                          <v-icon small>mdi-delete-restore</v-icon>
                        </v-btn>
                      </template>
                      <span>Restaurar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="blue"
                          dark
                          x-small
                          class="rounded-md mx-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="setTimeLine(item)"
                        >
                          <v-icon small> mdi-history </v-icon>
                        </v-btn>
                      </template>
                      <span>Historial</span>
                    </v-tooltip>
                  </template>
                  <template v-else>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          x-small
                          class="rounded-md mx-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="update(item)"
                        >
                          <v-icon small> mdi-pencil </v-icon>
                        </v-btn>
                      </template>
                      <span>Actualizar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="blue"
                          dark
                          x-small
                          class="rounded-md mx-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="setTimeLine(item)"
                        >
                          <v-icon small> mdi-history </v-icon>
                        </v-btn>
                      </template>
                      <span>Historial</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="red"
                          dark
                          x-small
                          class="rounded-md mx-1"
                          v-bind="attrs"
                          v-on="on"
                          :loading="item.isDeleting"
                          @click="setDeleteModal(item)"
                        >
                          <v-icon small> mdi-delete </v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="warning"
                          dark
                          x-small
                          class="rounded-md mx-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="redesign(item)"
                        >
                          <v-icon small>mdi-arrow-up-thick</v-icon>
                        </v-btn>
                      </template>
                      <span>Rediseño</span>
                    </v-tooltip>
                  </template>
                </template>

                <template #[`item.cqMax`]="{ item }">
                  <span>min: {{ item.cqMin }} </span>
                  <v-spacer></v-spacer>
                  <span>max: {{ item.cqMax }} </span>
                  <v-spacer></v-spacer>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="page"
          :length="numPages"
          total-visible="10"
          color="primary"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col v-if="showFlowComponent.component == 'FORM_MARKER'">
        <MolecularMarkerForm
          @back="
            showFlowComponent = {
              flag: false,
              component: '',
            }
          "
          @back_update="fetchAndClear"
        />
      </v-col>
      <v-col v-if="showFlowComponent.component == 'FORM_REDESIGN'">
        <RedesignMarkerForm
          @back="
            showFlowComponent = {
              flag: false,
              component: '',
            }
          "
          @back_update="fetchAndClear"
        />
      </v-col>
    </v-row>
    <TimeLineMarker v-model="showTimeLine" v-if="showTimeLine" />
    <DeleteCofirmationModal
      v-model="showDeleteCofirmationModal"
      v-if="showDeleteCofirmationModal"
      @confirmation="removeMarker()"
      :title="genericModalTitle"
    />
    <RestoreCofirmationModal
      v-model="showRestoreCofirmationModal"
      v-if="showRestoreCofirmationModal"
      @confirmation="activeMarker()"
      :title="genericModalTitle"
    />
  </v-container>
</template>

<script>
//Vuex
import { mapActions, mapMutations, mapState } from "vuex";
//components
//Libraries
import dayjs from "dayjs";
//modals
import MolecularMarkerForm from "@/components/cmda_admin/MolecularMarkerForm.vue";
import RedesignMarkerForm from "@/components/cmda_admin/RedesignMarkerForm.vue";
import TimeLineMarker from "@/components/cmda_admin/modals/TimeLineMarker.vue";
import DeleteCofirmationModal from "@/components/utils/GenericConfirmationModal.vue";
import RestoreCofirmationModal from "@/components/utils/GenericConfirmationModal.vue";
export default {
  components: {
    MolecularMarkerForm,
    TimeLineMarker,
    RedesignMarkerForm,
    DeleteCofirmationModal,
    RestoreCofirmationModal,
  },
  data: () => ({
    headers: [
      {
        text: "ID",
        sortable: false,
        value: "molecularMarkerID",
      },
      { text: "Versión", value: "version", sortable: false, align: "center" },

      { text: "Nombre", value: "microorganism_meta.name", sortable: false },
      { text: "Cq's", value: "cqMax", sortable: false },

      {
        text: "Tipo",
        value: "microorganism_meta.moType",
        sortable: false,
      },
      { text: "Fecha creación", value: "createdAt", sortable: false },
      { text: "Ultima actualización", value: "updatedAt", sortable: false },
      { text: "Acciones", value: "_id", sortable: false },
    ],
    markers: [],
    per_page: 10,
    page: 1,
    numPages: 1,
    sort: { createdAt: -1 },
    search: null,
    filter: {
      symbiosis: null,
      type: null,
      isActive: null,
    },
    moTypes: [
      {
        name: "Bacteria",
        value: "bacteria",
      },
      {
        name: "Hongo",
        value: "fungi",
      },
      {
        name: "Nematodo",
        value: "nematode",
      },
    ],
    symbiosis: [
      {
        name: "Benéfico",
        value: false,
      },
      {
        name: "Patógeno",
        value: true,
      },
    ],
    statusMarker: [
      {
        name: "Activo",
        value: "active",
      },
      {
        name: "Suspendido",
        value: "suspended",
      },
    ],
    loadTable: false,
    showFlowComponent: {
      flag: false,
      component: "",
    },
    filterMenu: false,
    showTimeLine: false,
    showDeleteCofirmationModal: false,
    showRestoreCofirmationModal: false,
    genericModalTitle: "",
  }),
  async mounted() {
    await this.fetchData();
    // await this.fetchDataBASF();
  },
  computed: {
    ...mapState("laboratory", ["molecularMarkerToUpdate"]),
  },
  methods: {
    ...mapActions("laboratory", [
      "getMarkers",
      "deleteMarker",
      "restoreMarker",
    ]),
    ...mapMutations("laboratory", ["SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async fetchData() {
      try {
        this.loadTable = true;
        let payload = {
          filter: this.filter,
          per_page: this.per_page,
          page: this.page,
          sort: this.sort,
        };
        let response = await this.getMarkers(payload);
        if (response.error) throw Error(response.message);
        this.numPages = response.pages;
        this.markers = response.molecularMarkers;
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.loadTable = false;
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
      return date || "--/--/----";
    },
    getMOType(type) {
      let itemsMoType = {
        all: "Todos",
        bacteria: "Bacteria",
        fungi: "Hongo",
        nematode: "Nemátodo",
      };
      return itemsMoType[type] || type;
    },
    async fetchAndClear() {
      this.showFlowComponent = {
        flag: false,
        component: "",
      };
      this.SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE(null);
      await this.fetchData();
    },
    async cleanFilter() {
      this.filterMenu = false;
      this.filter = {
        symbiosis: null,
        type: null,
        isActive: null,
      };
      await this.fetchData();
    },
    async applyFilter() {
      this.page = 1;
      this.numPages = 1;
      this.filterMenu = false;
      await this.fetchData();
    },
    update(item) {
      this.SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE(item);
      this.showFlowComponent = {
        flag: true,
        component: "FORM_MARKER",
      };
    },
    redesign(item) {
      this.SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE(item);
      this.showFlowComponent = {
        flag: true,
        component: "FORM_REDESIGN",
      };
    },
    setTimeLine(item) {
      this.SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE(item);
      this.showTimeLine = true;
    },
    setDeleteModal(item) {
      this.SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE(item);
      this.genericModalTitle = `¿Estas seguro que quiere eliminar el marcador ${item.microorganism_meta.name} (${item.molecularMarkerID})?`;
      this.showDeleteCofirmationModal = true;
    },
    setRestoreModal(item) {
      this.SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE(item);
      this.genericModalTitle = `¿Estas seguro que quiere restaurar el marcador ${item.microorganism_meta.name} (${item.molecularMarkerID})?`;
      this.showRestoreCofirmationModal = true;
    },
    openFormNewMarrker() {
      this.showFlowComponent = {
        flag: true,
        component: "FORM_MARKER",
      };
      this.SET_SELECTED_MOLECULAR_MARKER_TO_UPDATE(null);
    },
    async removeMarker() {
      try {
        this.molecularMarkerToUpdate.isDeleting = true;
        let payload = {
          markerId: this.molecularMarkerToUpdate._id,
        };
        await this.deleteMarker(payload);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: `Marcador eliminado con exito`,
          timeout: 5000,
        });

        this.page = 1;
        await this.fetchData();
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
    },
    async activeMarker() {
      try {
        this.molecularMarkerToUpdate.isDeleting = true;
        let payload = {
          markerId: this.molecularMarkerToUpdate._id,
        };
        await this.restoreMarker(payload);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: `Marcador restaurado con exito`,
          timeout: 5000,
        });

        this.page = 1;
        await this.fetchData();
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
    },
  },
  watch: {
    async page() {
      await this.fetchData();
    },

    async search(value) {
      this.page = 1;
      this.numPages = 1;
      if (value) {
        this.filter = {
          recommendationType: { $exists: false },
          search: value,
        };
      } else {
        this.filter = {
          recommendationType: { $exists: false },
        };
      }

      await this.fetchData();
    },
  },
};
</script>
<style scoped></style>
