<template>
  <v-container>
    <v-row v-if="!isAdding">
      <v-col>
        <v-row>
          <v-col cols="12" xl="5" lg="5" md="5">
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-magnify"
              label="Buscar..."
              clearable
              v-model="search"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" xl="1" lg="2" md="3">
            <v-menu
              offset-y
              :close-on-click="true"
              :close-on-content-click="false"
              v-model="filterMenu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on" block>
                  <v-icon left>mdi-filter</v-icon>
                  Filtro
                </v-btn>
              </template>
              <v-card width="auto">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          label="Tipo"
                          hide-details
                          outlined
                          clearable
                          dense
                          :items="moTypes"
                          hide-no-data
                          item-text="name"
                          v-model="filter.type"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          label="Simbiosis"
                          hide-details
                          outlined
                          clearable
                          dense
                          :items="symbiosis"
                          item-text="name"
                          hide-no-data
                          v-model="filter.symbiosis"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          label="Familia"
                          hide-details
                          outlined
                          clearable
                          dense
                          :items="families"
                          hide-no-data
                          v-model="filter.family"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="warning" @click="cleanFilter()"
                    >Restaurar</v-btn
                  >
                  <v-btn color="primary" @click="applyFilter()">Aplicar</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="12" xl="1" lg="2" md="3">
            <v-btn color="primary" @click="openFormCreate()">
              <v-icon>mdi-plus</v-icon>
              Añadir nivel(es)
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card elevation="3">
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="headers"
                :items="riskLevels"
                :page.sync="page"
                :items-per-page="10"
                :loading="loadTable"
                hide-default-footer
                fixed-header
                height="56vh"
                no-data-text="No hay niveles de riesgo"
                class="elevation-0"
              >
                <template #[`item.molecularMarker`]="{ item }">
                  <div v-if="item.molecularMarker.name">
                    <span
                      v-if="item.molecularMarker._id.isPathogen"
                      class="warning--text"
                      >{{ item.molecularMarker.name }}
                    </span>
                    <span v-else class="primary--text"
                      >{{ item.molecularMarker.name }}
                    </span>
                  </div>
                  <div v-else>
                    <div v-if="item.molecularMarker.moType == 'nematode'">
                      <span
                        v-if="item.molecularMarker.isPathogen"
                        class="blue--text"
                      >
                        Nematodo (Patógeno)
                      </span>
                      <span v-else class="blue--text">
                        Nematodo (Benéfico)</span
                      >
                    </div>
                    <div v-else-if="item.molecularMarker.moType == 'fungi'">
                      <span
                        v-if="item.molecularMarker.isPathogen"
                        class="blue--text"
                      >
                        Hongo (Patógeno)
                      </span>
                      <span v-else class="blue--text"> Hongo (Benéfico)</span>
                    </div>
                    <div v-else-if="item.molecularMarker.moType == 'bacteria'">
                      <span
                        v-if="item.molecularMarker.isPathogen"
                        class="blue--text"
                      >
                        Bactería (Patógeno)
                      </span>
                      <span v-else class="blue--text">
                        Bactería (Benéfico)</span
                      >
                    </div>
                  </div>
                </template>
                <template #[`item.updatedAt`]="{ item }">
                  <span>{{ dateFormat(item.updatedAt) }} </span>
                </template>
                <template #[`item.reliability`]="{ item }">
                  <span>{{ getReliability(item.reliability) }} </span>
                </template>
                <template #[`item.riskLevelLow`]="{ item }">
                  Bajo =
                  <span>min: {{ item.riskLevelLow.min }} </span>
                  /
                  <span>max: {{ item.riskLevelLow.max }} </span>
                  <v-spacer></v-spacer>
                  Medio =
                  <span>min: {{ item.riskLevelMedium.min }} </span>
                  /
                  <span>max: {{ item.riskLevelMedium.max }} </span>
                  <v-spacer></v-spacer>
                  Alto =
                  <span>min: {{ item.riskLevelHight.min }} </span>
                  /
                  <span>max: {{ item.riskLevelHight.max }} </span>
                </template>
                <template #[`item.__v`]="{ item }">
                  <div v-if="item.molecularMarker._id">
                    <span>min: {{ item.molecularMarker._id.cqMin }} </span>
                    <v-spacer></v-spacer>
                    <span>max: {{ item.molecularMarker._id.cqMax }} </span>
                    <v-spacer></v-spacer>
                  </div>
                  <div v-else>
                    <span> No aplica </span>
                  </div>
                </template>
                <template #[`item._id`]="{ item }">
                  <template v-if="item.molecularMarker.name">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="red"
                          dark
                          x-small
                          class="rounded-md mx-1"
                          @click="openDeleteModal(item)"
                          :loading="item.isDeleting"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon small> mdi-delete </v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        x-small
                        class="rounded-md mx-1"
                        @click="openFormUpdate(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small> mdi-pencil </v-icon>
                      </v-btn>
                    </template>
                    <span>Actualizar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="blue"
                        dark
                        x-small
                        class="rounded-md mx-1"
                        @click="openTimeLjne(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small> mdi-history</v-icon>
                      </v-btn>
                    </template>
                    <span>Actualizar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="page"
          :length="numPages"
          total-visible="10"
          color="primary"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <FormNewLevels
          @back="isAdding = false"
          @back_update="fetchData(), (isAdding = false)"
        />
      </v-col>
    </v-row>
    <DeleteCofirmationModal
      v-model="showDeleteCofirmationModal"
      v-if="showDeleteCofirmationModal"
      @confirmation="deleteLevel()"
      :title="deleteModalTitle"
    />
    <TimeLineLevel v-model="showTimeLine" v-if="showTimeLine" />
  </v-container>
</template>

<script>
//Vuex
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
//components
//Libraries
import dayjs from "dayjs";
//modals
import UpdateRiskLevelModal from "@/components/cmda_admin/modals/UpdateRiskLevelModal.vue";
import FormNewLevels from "@/components/cmda_admin/FormNewLevels.vue";
import DeleteCofirmationModal from "@/components/utils/GenericConfirmationModal.vue";
import TimeLineLevel from "@/components/cmda_admin/modals/TimeLineLevel.vue";
export default {
  components: {
    UpdateRiskLevelModal,
    FormNewLevels,
    DeleteCofirmationModal,
    TimeLineLevel,
  },
  data: () => ({
    headers: [
      {
        text: "Marcador molecular",
        sortable: false,
        value: "molecularMarker",
      },
      { text: "Familia", value: "family", sortable: false },
      {
        text: "Niveles de referencía",
        value: "riskLevelLow",
        sortable: false,
      },
      { text: "Cq's", value: "__v", sortable: false },
      {
        text: "Confiabilidad",
        value: "reliability",
        sortable: false,
        align: "center",
      },
      { text: "Ultima actualización", value: "updatedAt", sortable: false },
      { text: "Acciones", value: "_id", sortable: false },
    ],
    per_page: 10,
    page: 1,
    numPages: 1,
    sort: { createdAt: -1 },
    search: null,
    filter: {
      search: null,
      symbiosis: null,
      type: null,
      family: null,
    },
    loadTable: false,
    riskLevels: [],
    isAdding: false,
    filterMenu: false,
    moTypes: [
      {
        name: "Hongo",
        value: "fungi",
      },
      {
        name: "Bacteria",
        value: "bacteria",
      },
      {
        name: "Nematodo",
        value: "nematode",
      },
      {
        name: "Genérico",
        value: "other",
      },
    ],
    symbiosis: [
      {
        name: "Benéfico",
        value: false,
      },
      {
        name: "Patógeno",
        value: true,
      },
    ],
    families: [
      "Amaranthaceae",
      "Amaryllidaceae",
      "Anacardiaceae",
      "Annonaceae",
      "Apiaceae",
      "Araceae",
      "Arecaceae",
      "Asparagaceae",
      "Asphodelaceae",
      "Asteraceae",
      "Bixaceae",
      "Brassicaceae",
      "Bromeliaceae",
      "Cactaceae",
      "Caricaceae",
      "Chenopodiaceae",
      "Clusiaceae",
      "Convolvulaceae",
      "Cucurbitaceae",
      "Ebenaceae",
      "Ericaceae",
      "Euphorbiaceae",
      "Fabaceae",
      "Gramineae",
      "Gramíneas",
      "Juglandaceae",
      "Lamiaceae",
      "Lauraceae",
      "Liliaceae",
      "Linaceae",
      "Lythraceae",
      "Malpighiaceae",
      "Malvaceae",
      "Moraceae",
      "Moringaceae",
      "Musaceae",
      "Myrtaceae",
      "Oleaceae",
      "Orchidaceae",
      "Oxalidaceae",
      "Passifloraceae",
      "Pedaliaceae",
      "Pinaceae",
      "Piperaceae",
      "Portulacaceae",
      "Proteaceae",
      "Rosaceae",
      "Rubiaceae",
      "Rutaceae",
      "Sapindaceae",
      "Sapotaceae",
      "Solanaceae",
      "Vitaceae",
      "Zingiberaceae",
      "Otro",
    ],
    showDeleteCofirmationModal: false,
    deleteModalTitle: "",
    showTimeLine: false,
  }),
  async mounted() {
    await this.fetchData();
  },
  computed: {
    ...mapState("riskLevel", ["levelSelected"]),
  },
  methods: {
    ...mapActions("riskLevel", ["getLevels", "removeLevel"]),
    ...mapMutations("riskLevel", ["SET_CURRENT_LEVEL"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async fetchData() {
      try {
        this.loadTable = true;
        let payload = {
          filter: this.filter,
          per_page: this.per_page,
          page: this.page,
          sort: this.sort,
        };
        let response = await this.getLevels(payload);
        if (response.error) throw Error(response.message);
        this.numPages = response.pages;
        this.riskLevels = response.levels;
        console.log(this.riskLevels);
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
      this.loadTable = false;
    },
    dateFormat(value) {
      const date = `${dayjs(value).format("DD/MM/YYYY - HH:mm")} hrs`;
      return date || "--/--/----";
    },
    async cleanFilter() {
      this.filterMenu = false;
      this.filter.symbiosis = null;
      this.filter.type = null;
      this.filter.family = null
      await this.fetchData();
    },
    async applyFilter() {
      this.page = 1;
      this.numPages = 1;
      this.filterMenu = false;
      await this.fetchData();
    },
    openDeleteModal(item) {
      this.SET_CURRENT_LEVEL(item);
      this.deleteModalTitle = `¿Esta seguro que desea eliminar este nivel de riesgo ${item.molecularMarker.name} (${item.family})?`;
      this.showDeleteCofirmationModal = true;
    },
    openFormUpdate(item) {
      this.SET_CURRENT_LEVEL(item);
      this.isAdding = true;
    },
    openTimeLjne(item) {
      this.SET_CURRENT_LEVEL(item);
      this.showTimeLine = true;
    },
    openFormCreate() {
      this.SET_CURRENT_LEVEL(null);
      this.isAdding = true;
    },
    async deleteLevel() {
      try {
        this.levelSelected.isDeleting = true;
        let payload = {
          levelId: this.levelSelected._id,
        };
        await this.removeLevel(payload);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: `Nivel eliminado con exito`,
          timeout: 5000,
        });
        this.SET_CURRENT_LEVEL(null);
        this.page = 1;
        await this.fetchData();
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
    },
    getReliability(value) {
      let reliabilityEnum = {
        low: "Baja",
        medium: "Media",
        hight: "Alta",
      };
      return reliabilityEnum[value] || value;
    },
  },
  watch: {
    async page() {
      await this.fetchData();
    },

    async search(value) {
      this.page = 1;
      this.numPages = 1;
      this.filter.search = value;
      await this.fetchData();
    },
  },
};
</script>
<style scoped></style>
